import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {useParams} from "react-router-dom";
import {ContentItem} from "../types/TypeFirebaseContent";
import {ContentRepository} from "../lib/firebaseRepository";
import ReactPixel from 'react-facebook-pixel';

const Content = styled.div`
  margin-top: 26px;
  padding: 0 16px;
`;

const CaptionText = styled.p<{ color?: string; }>`
    font-weight: 600;
    font-size: 13px;
    line-height: ${13 * 1.5}px;
    color: #A3AAB2;
`;

function ContentPage() {
	const {pageId} = useParams<{ pageId: string; }>();
	const [pageItem, setPageItem] = useState<ContentItem | null>(null);

	useEffect(()=>{
		console.log("##### pageId: ", pageId);
		try{
			pageId && new ContentRepository().getDetailItem(pageId).then((result)=> {
				setPageItem(result)
				return result;
			}).then((result)=>{
				ReactPixel.pageView()

				ReactPixel.track('ViewContent', {
					content_ids: pageId,
					content_name: result.title,
					content_type: result.type,
					currency: 'KRW',
				})
			})
		}catch(ex){

		}
	}, [pageId]);

	if(!pageId || !pageItem) return null;
	return (
		<>
			<div>
				<Content>
					<img src={pageItem.mainImg} width="100%" alt=""/>
					<CaptionText>
						{/*{item.caption}*/}
					</CaptionText>
				</Content>
			</div>
		</>
	);
}

export default ContentPage;