import {getDatabase, ref, onValue} from 'firebase/database'
import {ContentItem, SectionItem} from "../types/TypeFirebaseContent";

export  class ContentRepository{
	public getDetailItem = async (contentId:string):Promise<ContentItem>=>{
		return new Promise((resolve, reject) => {
			const db = getDatabase();
			const contentRef = ref(db, `contents/${contentId}`);
			onValue(contentRef, (snapshot)=>{
				const data: ContentItem = snapshot.val();


				resolve({
					title: data.title,
					description: data.description,
					reporter: data.reporter,
					type: data.type,
					mainImg: data.mainImg,
					thumbnailImage:data.thumbnailImage
				});
			}, (error)=>{
				console.log(error);
			})
		})
	}
}
