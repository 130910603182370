import React from 'react';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import ContentPage from "./page/ContentPage";
import {GlobalStyles} from "./components/GlobalStyles";
import { initializeApp } from "firebase/app";
import ReactPixel from "react-facebook-pixel";

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_AP_KEY,
  authDomain: "tma-app-f387c.firebaseapp.com",
  databaseURL: "https://tma-app-f387c-default-rtdb.firebaseio.com",
  projectId: "tma-app-f387c",
  storageBucket: "tma-app-f387c.appspot.com",
  messagingSenderId: "935360510589",
  appId: "1:935360510589:web:4dbc59bce69655f8268f14",
  measurementId: "G-8FHH45X1CH"
}
ReactPixel.init('621792646730460');
initializeApp(firebaseConfig);

function App() {
  const routes = createBrowserRouter([
    {
      path:'/:pageId',
      element: <ContentPage/>
    }
  ])
  return (
    <>
      <GlobalStyles />
      <RouterProvider router={routes}/>
    </>
  );
}

export default App;
